import router from "@/router/index.js";
// import { baseUrl } from "@/globals/network/apiSettings";
import ApiController from "@/globals/network/apis";
import { setStorageAfterLogin } from "@/helpers/auth";

const auth = {
  namespaced: true,
  state: {
    form: {
      email: "",
      password: "",
    },
    errors: {
      email: "",
      password: "",
    },
    registerForm: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    registerErrors: {
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationError: "",
    regValError: "",
  },
  getters: {},
  actions: {
    async login({ state, commit }) {
      const errors = {};

      if (!state.form.email) {
        errors.email = "Email is required";
      } else if (
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(state.form.email)
      ) {
        errors.email = "Invalid email format";
      }
      if (!state.form.password) {
        errors.password = "Password is required";
      } else if (state.form.password.length < 8) {
        errors.password = "Password must be at least 8 characters long";
      }

      if (Object.keys(errors).length === 0) {
        const formData = state.form;
        let credentials;
        try {
          credentials = {
            email: formData.email,
            password: formData.password,
            is_admin: false,
          };
          console.log("Credentials to be sent:", credentials);
          ApiController.loginCall(credentials, (response) => {
            if (response.success) {
              setStorageAfterLogin(response.data);
              (formData.email = ""),
                (formData.password = ""),
                router.push("/templates");
            } else {
              console.log("Reporting for", response);
              console.log("Reporting message", response.message);
              if(response.message) {
                commit("setValidationError", response.message);
              } else {
                commit("setValidationError", 'Something went wrong. Please try again later.');
              }
            }
          });
        } catch (error) {
          console.log(
            "🚀 ~ file: auth.js:77 ~ login ~ error:",
            error.response.message
          );
          commit("setLoginErrors", error.response.message);
        }
      } else {
        commit("setLoginErrors", errors);
        console.log("Form validation errors:", errors);
      }
    },
    async register({ state, commit }) {
      const registerErrors = {};

      if (!state.registerForm.firstName) {
        registerErrors.firstName = "First Name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(state.registerForm.firstName)) {
        registerErrors.firstName = "First Name can only contain alphabets";
      }
      if (!state.registerForm.lastName) {
        registerErrors.lastName = "Last Name is required";
      } else if (!/^[a-zA-Z\s]+$/.test(state.registerForm.lastName)) {
        registerErrors.lastName = "Last Name can only contain alphabets";
      }
      if (!state.registerForm.username) {
        registerErrors.username = "Username is required";
      }
      if (!state.registerForm.phone) {
        registerErrors.phone = "Phone Number is required";
      } else if (!/^[a-zA-Z0-9]+$/.test(state.registerForm.phone)) {
        registerErrors.phone = "Phone number cannot contain special characters";
      } else if (/[a-zA-Z]/.test(state.registerForm.phone)) {
        registerErrors.phone = "Phone number cannot contain alphabets";
      }
      if (!state.registerForm.email) {
        registerErrors.email = "Email is required";
      } else if (
        !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
          state.registerForm.email
        )
      ) {
        registerErrors.email = "Invalid email format";
      }
      if (!state.registerForm.password) {
        registerErrors.password = "Password is required";
      } else if (state.registerForm.password.length < 8) {
        registerErrors.password = "Password must be at least 8 characters long";
      }
      if (!state.registerForm.confirmPassword) {
        registerErrors.confirmPassword = "Confirm Password is required";
      } else if (
        state.registerForm.confirmPassword !== state.registerForm.password
      ) {
        registerErrors.confirmPassword = "Password Fields do not match";
      }

      if (Object.keys(registerErrors).length === 0) {
        const formData = state.registerForm;
        let credentials;
        try {
          credentials = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            username: formData.username,
            phone_no: formData.phone,
            email: formData.email,
            password: formData.confirmPassword,
          };
          console.log("Credentials to be sent:", credentials);
          ApiController.register(credentials, (response) => {
            if (response.success) {
              (formData.firstName = ""),
                (formData.lastName = ""),
                (formData.username = ""),
                (formData.phone = ""),
                (formData.email = ""),
                (formData.password = ""),
                (formData.confirmPassword = ""),
                router.push("/login");
            } else {
              console.log("Reporting for Registration", response);
              if(response.message) {
                commit("setRegValError", response.message);
              } else {
                commit("setRegValError", "Something went wrong. Please try again later.");
              }
            }
          });
        } catch (error) {
          console.log("Oh no 😢", error);
        }
      } else {
        commit("setRegisterErrors", registerErrors);
        console.log("Form validation errors:", registerErrors);
      }
    },
  },
  mutations: {
    setLoginErrors(state, errors) {
      state.errors = errors;
    },
    setRegisterErrors(state, registerErrors) {
      state.registerErrors = registerErrors;
    },
    setValidationError(state, message) {
      state.validationError = message;
    },
    setRegValError(state, message) {
      state.regValError = message;
    },
  },
};

export default auth;
