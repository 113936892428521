import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },

  // Auth Route Start

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/LoginView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/RegisterView.vue"),
  },

  // Auth Routes End

  {
    path: "/templates",
    name: "templates",
    component: () => import("@/views/internal/TemplatesView.vue"),
  },
  {
    path: "/template/:slug",
    name: "dynamic_template",
    component: () => import("@/views/internal/DynamicTemplateView.vue"),
  },
  {
    path: "/chat-bot",
    name: "chatbot",
    component: () => import("@/views/internal/ChatbotView.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/internal/HistoryView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

const nonAuthorizedRoutes = ["login", "register"];

const authorizedUserRoutes = [
  "templates",
  "dynamic_template",
  "activity_suggestion",
  "general_lesson",
  "worksheet_generator",
  "unit_planner",
  "chatbot",
  "history",
];

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("access_token") || "";
  if (token != "") {
    if (!authorizedUserRoutes.includes(to.name || "")) {
      next({ name: "templates" });
    } else next();
  } else {
    if (!nonAuthorizedRoutes.includes(to.name || "")) {
      next({ name: "login" });
    } else next();
  }
});

export default router;
