// Library Imports
import axios from "axios";
import { getToken } from "@/helpers/auth";

// Local Imports
import { baseUrl } from "@/globals/network/apiSettings";

class apiService {
  //************************* Class Private Functions *******************************//
  getAuthorizationFormData = () => {
    // const token = "";
    const token = getToken();

    if (token != "") {
      let headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      axios.defaults.headers = headers;
      return axios;
    } else {
      let headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      };
      axios.defaults.headers = headers;
      return axios;
    }
  };

  getAuthorization = () => {
    // const token = localStorage.getItem("authToken") || "";
    const token = getToken();

    if (token != "") {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios.defaults.headers = headers;
      return axios;
    } else {
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      axios.defaults.headers = headers;
      return axios;
    }
  };

  getBasicHeaders = () => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    axios.defaults.headers = headers;
    return axios;
  };

  #processResponse = (status, endPoint, response) => {
    if (status) {
      let data = {
        success: response?.data?.success ?? false,
        data: response?.data?.data,
        message: response?.data?.message,
      };
      console.log(
        `%c ${endPoint}`,
        "color: green; font-family:sans-serif; font-size: 20px; font-weight: 700",
        data
      );
      return data;
    } else {
      console.log(
        "🚀 ~ file: apiServices.js ~ line 56 ~ ",
        response?.response?.data?.message
      );
      if (response?.response?.status == 401) {
        console.log("ya to gayo");
      }
      let data = {
        success: response?.response?.success ?? false,
        code: response?.response?.status,
        message: response?.response?.data.message,
        errors: response?.response?.data.errors
          ? response?.response?.data.errors
          : "",
      };
      console.log(
        `%c ${endPoint}`,
        "color: red; font-family:sans-serif; font-size: 20px; font-weight: 700",
        data
      );
      return data;
    }
  };

  //************************* Get Request Functions *******************************//

  sendGetWithAuth = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .get(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  sendGetWithoutAuth = (payload, endPoint, callback) => {
    const axiosWithoutAuth = this.getBasicHeaders();

    axiosWithoutAuth
      .get(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  //************************* Post Request Functions *******************************//

  sendPostWithAuth = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorization();

    axiosWithAuth
      .post(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  sendPostWithoutAuth = (payload, endPoint, callback) => {
    const axiosWithoutAuth = this.getBasicHeaders();

    axiosWithoutAuth
      .post(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };

  sendPostWithAuthAndFormData = (payload, endPoint, callback) => {
    const axiosWithAuth = this.getAuthorizationFormData();

    axiosWithAuth
      .post(`${baseUrl}/${endPoint}`, payload)
      .then((response) => {
        callback(this.#processResponse(true, endPoint, response));
      })
      .catch((response) => {
        callback(this.#processResponse(false, endPoint, response));
      });
  };
}

const ApiServices = new apiService();
export default ApiServices;
