export function setStorageAfterLogin(data) {
  localStorage.setItem("access_token", data.token.access);
  localStorage.setItem("user_id", data.id);
  // localStorage.setItem("user", JSON.stringify(data.user));
  localStorage.setItem(
    "user",
    JSON.stringify({
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_no: data.phone_no,
    })
  );
}

export function getStorage() {
  const access_token = localStorage.getItem("access_token");
  const user = localStorage.getItem("user");

  return {
    access_token: access_token,
    user: user != "" ? JSON.parse(user) : "",
  };
}

export function getToken() {
  return localStorage.getItem("access_token") || "";
}

export function logout() {
  localStorage.setItem("access_token", "");
  localStorage.setItem("user", "");
  return true;
}

export function get_user_id() {
  return localStorage.getItem("user_id") || "";
}

