const modal = {
  namespaced: true,
  state: {
    historyModal: false,
  },
  getters: {
    historyModalHidden: (state) => {
      return !state.historyModal ? "hidden" : "";
    },
  },
};

export default modal;
