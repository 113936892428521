const socket = {
  state: {
    templateMessages: [],
    chatbotMessages: [],
    counter: 0,
  },
  mutations: {
    addTemplateMessage(state, message) {
      // console.log("AddTemplateMessage : ", message);
      // state.templateMessages.push(message);
      // if(state.templateMessages.length < (state.counter + 1)) {
      //   state.templateMessages.push(message);
      // } else if(message.message) {
      state.templateMessages[state.counter].message += message.message;
      // }

      if(message.streaming_complete == true) {
        state.counter = state.counter + 1;
      }
    },
    addQuestionsToTemplateMessage(state, inputs) {
      console.log("AddTemplateMessage : ", inputs);
      state.templateMessages.push(inputs);
      state.templateMessages[state.counter].message = "";
      // if(state.templateMessages.length < (state.counter + 1)) {
      //   state.templateMessages.push();
      // } else if(message.message) {
      //   state.templateMessages[state.counter].message += message.message;
      // }
    },
    addChatbotMessage(state, message) {
      console.log("AddChatbotMessage : ", message);
      state.chatbotMessages.push(message);
    },
    clearWebsocketMessages(state) {
      state.templateMessages = [];
      state.chatbotMessages = [];
    },
  },
  actions: {
    addTemplateMessage({ commit }, message) {
      // console.log("hello from store", message);
      commit("addTemplateMessage", message);
    },
    addQuestionsToTemplateMessage({ commit }, inputs) {
      console.log("hello from addQuestionsToTemplateMessage", inputs);
      commit("addQuestionsToTemplateMessage", inputs);
    },
    addChatbotMessage({ commit }, message) {
      console.log("hello from chatbot store", message);
      commit("addChatbotMessage", message);
    },
    clearWebsocketMessages({ commit }) {
      commit("clearWebsocketMessages");
    },
  },
  getters: {
    getTemplateMessages: (state) => state.templateMessages,
    getChatbotMessages: (state) => state.chatbotMessages,
  },
};

export default socket;
