import { createStore } from "vuex";
import auth from "./modules/auth";
import modal from "./modules/modal";
import socket from "./modules/socket";

const index = createStore({
  modules: {
    auth,
    modal,
    socket,
  },
});

export default index;
