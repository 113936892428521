//************************* KEY Constants *******************************//

//************************* URL Constants *******************************//
// export const baseUrl = "http://13.235.45.210";
export const baseUrl = "http://13.232.120.129";
// export const baseUrl = process.env.VUE_APP_BACKEND_URL;

//************************* End Points Constants *******************************//
// const external_prepend = "api/";
// const internal_prepends = "tenant/api/";
export const endPoints = {
  //************************* Login *******************************//
  // Login
  login: "users/login/",

  // Register
  register: "users/register/",
  //************************* Login *******************************//

  //************************* Templates *******************************//
  // Dynamic Template
  dynamicTemplate: "template/listing/",

  // Templates Detail
  templatesDetail: "template/detail/",

  // Dynamic Template History
  dynamicTemplateHistory: "template/template_history/",
  //************************* Templates *******************************//

  //************************* History *******************************//

  // user history
  userHistory: "template/all-history",

  // Chat History
  chatHistory: "chat/history/",

  //************************* History *******************************//
};
