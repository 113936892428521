// Library Imports
import ApiServices from "@/globals/network/apiServices";

// Local Imports
import { endPoints } from "@/globals/network/apiSettings";

class controller {
  //************************* Login *******************************//
  // login
  loginCall = (payload, callback) => {
    return ApiServices.sendPostWithoutAuth(payload, endPoints.login, callback);
  };

  // register
  register = (payload, callback) => {
    return ApiServices.sendPostWithoutAuth(
      payload,
      endPoints.register,
      callback
    );
  };
  //************************* Login *******************************//

  //************************* Templates *******************************//
  // Dynamic Template
  dynamicTemplate = (payload, callback) => {
    return ApiServices.sendGetWithAuth(
      payload,
      endPoints.dynamicTemplate,
      callback
    );
  };

  // Dynamic Templates Detail
  templatesDetail = (payload, slug, callback) => {
    return ApiServices.sendGetWithAuth(
      payload,
      endPoints.templatesDetail + slug,
      callback
    );
  };

  // Dynamic Templates History
  dynamicTemplateHistory = (payload, slug, callback) => {
    return ApiServices.sendGetWithAuth(
      payload,
      endPoints.dynamicTemplateHistory + slug,
      callback
    );
  };
  //************************* Templates *******************************//

  // User All History
  userHistory = (payload, callback) => {
    return ApiServices.sendGetWithAuth(
      payload,
      endPoints.userHistory,
      callback
    );
  };

  // User Chat History
  chatHistory = (payload, callback) => {
    return ApiServices.sendGetWithAuth(
      payload,
      endPoints.chatHistory,
      callback
    );
  };

  
}

const ApiController = new controller();
export default ApiController;
